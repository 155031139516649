import { toast } from "react-toastify";
import axios from "axios";

const baseURL = "https://leverpay-api.azurewebsites.net/api/v1/";

const httpClient = axios.create({
  baseURL,
});

const setAuthHeader = (jwt) => {
  httpClient.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
};

export const fetchVerificationStatus = async (jwt) => {
  setAuthHeader(jwt);

  try {
    const response = await httpClient.get("brails-kyc/check-kyc-status");

    if (response.data.success) {
      const receiverData = response.data;

      return receiverData;
    }
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      console.log(err.response.data.data);
    }
    console.error("API call error:", err);
  }
};

export const verifyOtp = async (type, otp, jwt) => {
  toast.loading("Loading...");
  setAuthHeader(jwt);

  try {
    const response = await httpClient.post("brails-kyc/verify-otp", {"type": type, "otp": otp});
    toast.dismiss()

    if (response.data.success) {
      const receiverData = response.data;
      return receiverData;
    }
  } catch (err) {
    toast.dismiss()
    if (err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.data);
    }
    console.error("API call error:", err);
  }
};

export const emailVerification = async (email, jwt) => {
  toast.loading("Loading...");
  setAuthHeader(jwt);

  try {
    const response = await httpClient.post("brails-kyc/send-email-verification-otp", {"email": email});
    toast.dismiss()

    if (response.data.success) {
      const receiverData = response.data;
      return receiverData;
    }
  } catch (err) {
    toast.dismiss()
    if (err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.data);
    }
    console.error("API call error:", err);
  }
};

export const phoneVerification = async (phone, jwt) => {
  setAuthHeader(jwt);

  try {
    const response = await httpClient.post("brails-kyc/send-phone-verification-otp", {"phone": phone});

    toast.dismiss()

    if (response.data.success) {
      const receiverData = response.data.data[0];

      return receiverData;
    }
  } catch (err) {
    toast.dismiss()
    if (err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.data);
    }
    console.error("API call error:", err);
  }
};
