import React, { useEffect, useState } from "react";
import FieldVerifyModal from "../../components/Verification/FieldVerifyModal/FieldVerifyModal";
import "./KycVerification.css";
import Step from "../../components/Verification/FieldVerifyModal/Step";
import CameraField from "../../components/Verification/Camera/CameraField";
import SuccessfullModal from "../../components/Verification/Modal/SuccessfullModal";
import { toast, ToastContainer } from "react-toastify";
import { useLocalState } from "../../utils/useLocalStorage";
import {
  emailVerification,
  fetchVerificationStatus,
  phoneVerification,
  verifyOtp
} from "../../services/kycService";

const KycVerification = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill("")); // OTP state
  const [isOtpEntered, setIsOtpEntered] = useState(false); // State to switch between OTP and confirmation
  const [fieldToVerify, setFieldToVerify] = useState(""); // Track the field being verified
  const [imageUrl, setImageUrl] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [isImageCaptured, setIsImageCaptured] = useState(false);
  const [utilitySrc, setUtilitySrc] = useState(null);
  const [submit, setSubmit] = useState(false);

  const [status, setStatus] = useState(null);

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [bvn, setBvn] = useState("");
  const [nin, setNin] = useState("");

  const [fieldMsg, setFieldMsg] = useState("");

  const [verifiedSteps, setVerifiedSteps] = useState({
    email: false,
    bvn: false,
    nin: false,
    phoneNumber: false,
    profileImage: false,
    address: false,
  });

  const [jwt] = useLocalState("", "jwt");

  // const handleVerify = (field) => {
  //   setFieldToVerify(field); // Set the field being verified
  //   setIsModalOpen(true);
  // };

  const fetchStatus = async () => {
    try {
      const response = await fetchVerificationStatus(jwt);
      console.log(response);
      if (response.success) {
        setStatus(response.data);
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  const handleVerifyEmail = async (email) => {
    const regex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    const validEmail = regex.test(email);

    if (!validEmail) {
      toast.error("Enter valid email address!");
      return;
    }

    try {
      const response = await emailVerification(email, jwt);
      console.log(response);

      if (response.success) {
        setFieldToVerify("email");
        setFieldMsg(email);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;

    if (isNaN(value)) {
      return;
    }
    setPhone(value);
  };

  const handleVerifyPhone = async (phone) => {
    // Check if the phone number starts with '0' and replace it with '234'
    let modifiedPhone = phone;
    if (phone.length != 11) {
      toast.error("ENter complete 11 digits");
      return;
    }

    toast.loading("Loading...");

    if (phone.startsWith("0")) {
      modifiedPhone = `234${phone.slice(1)}`;
    }

    try {
      console.log(phone);
      const response = await phoneVerification(phone, jwt);

      if (true) {
        setFieldToVerify("phoneNumber");
        setFieldMsg(phone);
        setIsModalOpen(true);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleBvnChange = (e) => {
    const { value } = e.target;

    if (isNaN(value)) {
      return;
    }
    setBvn(value);
  };

  const handleVerifyBvn = (bvn) => {
    if (bvn.length != 11) {
      toast.error("Enter exactly 11 bvn digits");
      return;
    }

    setFieldToVerify("bvn");
    setIsOtpEntered(true);
    setIsModalOpen(true);
  };

  const handleNinChange = (e) => {
    const { value } = e.target;

    if (isNaN(value)) {
      return;
    }
    setNin(value);
  };

  const handleVerifyNin = (nin) => {
    if (bvn.length != 11) {
      toast.error("Enter exactly 11 bvn digits");
      return;
    }

    setFieldToVerify("nin");
    setIsOtpEntered(true);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOtpChange = (e, fieldIndex) => {
    if (isNaN(e.target.value)) return false;

    setOtp([
      ...otp.map((data, index) =>
        index === fieldIndex ? e.target.value : data
      ),
    ]);

    if (e.target.value && e.target.nextSibling) {
      e.target.nextSibling.focus();
    }
  };

  const handleConfirmOtp = async () => {
    if (otp.every((digit) => digit !== "")) {
      // Move to the confirmation step
      // handleContinue();
      const joinedOtp = otp.join("")
      try {
        let response = await verifyOtp(fieldToVerify, joinedOtp, jwt);
        console.log(response);

        if (response.success) {
          setIsOtpEntered(true);
        }
      } catch (error) {
        console.log(error);
        toast.error(error);
      }
    } else {
      console.log("Didnt work");
    }
  };

  // const handleContinue = () => {
  //   if (isOtpEntered) {
  //     setIsModalOpen(false);

  //     // Mark the current step as verified based on the field being verified
  //     const fieldMap = {
  //       email: "email",
  //       phoneNumber: "phoneNumber",
  //       bvn: "bvn",
  //       nin: "nin",
  //       profileImage: "profileImage",
  //       address: "address",
  //     };

  //     setVerifiedSteps({ ...verifiedSteps, [fieldMap[fieldToVerify]]: true });

  //     // Proceed to the next step
  //     setCurrentStep(currentStep + 1);

  //     // Reset OTP state
  //     setOtp(new Array(4).fill(""));
  //     setIsOtpEntered(false);
  //     setFieldToVerify(""); // Clear the field being verified
  //   }
  // };

  const handleContinue = () => {
    if (fieldToVerify === "profileImage" && isImageCaptured) {
      // Mark profile image as verified
      setVerifiedSteps({ ...verifiedSteps, profileImage: true });
      setOpenCamera(false);
      setIsModalOpen(true); // Open the modal for confirmation
      setIsOtpEntered(true); // Directly move to the verification status step
    } else if (isOtpEntered) {
      setIsModalOpen(false);

      // Mark the current step as verified based on the field being verified
      const fieldMap = {
        email: "email",
        phoneNumber: "phoneNumber",
        bvn: "bvn",
        nin: "nin",
        profileImage: "profileImage",
        address: "address",
        UtilityBill: "utility",
      };

      setVerifiedSteps({ ...verifiedSteps, [fieldMap[fieldToVerify]]: true });

      // Proceed to the next step
      setCurrentStep(currentStep + 1);

      // Reset OTP state
      setOtp(new Array(4).fill(""));
      setIsOtpEntered(false);
      setFieldToVerify(""); // Clear the field being verified
    }
  };

  const handleCapture = (imageSrc) => {
    const imageName = "captured_image.jpg";
    setImageUrl(imageSrc);
    setIsImageCaptured(true);
    setOpenCamera(false);
    setIsModalOpen(true);
    setIsOtpEntered(true);
    setFieldToVerify("ProfileImage");
  };

  const handleUtilityUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setUtilitySrc(imageUrl);
    }
  };

  const handleSubmit = () => {
    console.log("hello");
    setSubmit(true);
  };

  return (
    <div className="kyc_verification_main_container">
      <ToastContainer />
      <h1>KYC Verification</h1>

      <div className="kyc_fieldstep_container_wrapper">
        <div className="kyc_fieldstep_container">
          <h2>Dollar Card KYC Verification process</h2>

          <div className="step_outer_wrapper">
            <Step currentStep={currentStep} />
          </div>

          <div className="kyc_form_wrapper">
            <div className="general_form_field">
              <label>Email:</label>
              <div className="input_button_container">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={status && status.email == "verified"}
                  placeholder="Patrick001@gmail.com"
                  className={(status && status.email == "verified") ? "input-disabled" : ""}
                />
                <button
                  onClick={() => handleVerifyEmail(email)}
                  disabled={status && status.email == "verified"}
                  className={
                    status && status.email == "verified"
                      ? "button-verified"
                      : ""
                  }
                >
                  {status && status.email == "verified" ? "Verified" : "Verify"}
                </button>
              </div>
            </div>

            {status && status.email == "verified" && (
              <div className="general_form_field">
                <label>Phone:</label>
                <div className="input_button_container">
                  <input
                    type="text"
                    disabled={verifiedSteps.phoneNumber}
                    value={phone}
                    onChange={handlePhoneChange}
                    maxLength={11}
                    className={
                      verifiedSteps.phoneNumber ? "input-disabled" : ""
                    }
                  />
                  <button
                    onClick={() => handleVerifyPhone(phone)}
                    disabled={verifiedSteps.phoneNumber}
                    className={
                      verifiedSteps.phoneNumber ? "button-verified" : ""
                    }
                  >
                    {verifiedSteps.phoneNumber ? "Verified" : "Verify"}
                  </button>
                </div>
              </div>
            )}

            {currentStep >= 3 && (
              <div className="general_form_field">
                <label>BVN:</label>
                <div className="input_button_container">
                  <input
                    type="text"
                    disabled={verifiedSteps.bvn}
                    className={verifiedSteps.bvn ? "input-disabled" : ""}
                    value={bvn}
                    maxLength={11}
                    onChange={handleBvnChange}
                  />
                  <button
                    onClick={() => handleVerifyBvn(bvn)}
                    disabled={verifiedSteps.bvn}
                    className={verifiedSteps.bvn ? "button-pending" : ""}
                  >
                    {verifiedSteps.bvn ? "Pending" : "Verify"}
                  </button>
                </div>
              </div>
            )}

            {currentStep >= 4 && (
              <div className="general_form_field">
                <label>NIN:</label>
                <div className="input_button_container">
                  <input
                    type="text"
                    disabled={verifiedSteps.nin}
                    onChange={handleNinChange}
                    value={nin}
                    className={verifiedSteps.nin ? "input-disabled" : ""}
                    maxLength={11}
                  />
                  <button
                    onClick={() => handleVerifyNin(nin)}
                    disabled={verifiedSteps.nin}
                    className={verifiedSteps.nin ? "button-pending" : ""}
                  >
                    {verifiedSteps.nin ? "Pending" : "Verify"}
                  </button>
                </div>
              </div>
            )}

            {currentStep >= 5 && (
              <div className="general_form_field">
                <label>Image:</label>
                <div className="input_button_container">
                  <input
                    type="text"
                    id="image"
                    disabled={verifiedSteps.profileImage}
                    readOnly
                    value={isImageCaptured ? "captured_image.jpg" : ""}
                    className={
                      verifiedSteps.profileImage
                        ? "input-disabled small_file_input"
                        : "small_file_input"
                    }
                  />
                  {isImageCaptured ? (
                    <img
                      src={imageUrl}
                      alt="Captured"
                      style={{ height: "60px" }}
                    />
                  ) : (
                    <button
                      onClick={() => setOpenCamera(true)}
                      disabled={verifiedSteps.profileImage}
                      className={
                        verifiedSteps.profileImage ? "button-verified" : ""
                      }
                    >
                      Camera
                    </button>
                  )}
                </div>
              </div>
            )}

            {currentStep >= 6 && (
              <div className="general_form_field">
                <label>Address:</label>
                <div className="input_button_container">
                  <textarea
                    disabled={verifiedSteps.address}
                    required
                    className={verifiedSteps.address ? "input-disabled" : ""}
                  ></textarea>
                </div>
              </div>
            )}

            {currentStep >= 6 && (
              <div className="general_form_field">
                <label>
                  Upload Utility Bill{" "}
                  <span className="label_span">
                    (It must have same address with what your Provide)
                  </span>
                </label>
                <div className="input_button_container">
                  <input
                    type="file"
                    disabled={verifiedSteps.address}
                    required
                    className={verifiedSteps.address ? "input-disabled" : ""}
                    onChange={handleUtilityUpload}
                  />
                  {utilitySrc && (
                    <img
                      src={utilitySrc}
                      alt="utility bill"
                      style={{ height: "50px" }}
                    />
                  )}
                </div>
              </div>
            )}

            {currentStep >= 6 && (
              <button className="kyc_submit_btn" onClick={handleSubmit}>
                Submit
              </button>
            )}
          </div>
        </div>
      </div>

      {openCamera && (
        <CameraField setImageUrl={setImageUrl} onCapture={handleCapture} />
      )}

      {submit && <SuccessfullModal />}

      <FieldVerifyModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onOtpChange={handleOtpChange}
        otp={otp}
        onConfirmOtp={handleConfirmOtp}
        onContinue={handleContinue}
        isOtpEntered={isOtpEntered}
        setIsOtpEntered={setIsOtpEntered}
        fieldToVerify={fieldToVerify}
        fieldMsg={fieldMsg}
      />
    </div>
  );
};

export default KycVerification;
